import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/HomePage/Banner/Banner";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import KeyFeatures from "../../components/HomePage/KeyFeatures/KeyFeatures";
import Various from "../../components/HomePage/Various/Various";
import Tools from "../../components/HomePage/Tools/Tools";
import Transparent from "../../components/HomePage/Transparent/Transparent";
import Ambitious from "../../components/HomePage/Ambitious/Ambitious";
import Explore from "../../components/HomePage/Explore/Explore";
import Accounts from "../../components/HomePage/Accounts/Accounts";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo</title>
        <meta name="title" content="Welcome to Brabo - Empowering Your Trading Journey" />
        <meta property="og:title" content="Welcome to Brabo - Empowering Your Trading Journey" />
        <meta property="twitter:title" content="Welcome to Brabo - Empowering Your Trading Journey" />

        <meta name="description" content="Discover a new dimension of trading excellence with Brabo, the premier platform that equips you with the tools and support to take charge of your financial destiny. Trade stocks, currencies, commodities, and more, with transparent fees and advanced trading tools. Start your journey today and unlock boundless possibilities." />
        <meta property="og:description" content="Discover a new dimension of trading excellence with Brabo, the premier platform that equips you with the tools and support to take charge of your financial destiny. Trade stocks, currencies, commodities, and more, with transparent fees and advanced trading tools. Start your journey today and unlock boundless possibilities." />
        <meta property="twitter:description" content="Discover a new dimension of trading excellence with Brabo, the premier platform that equips you with the tools and support to take charge of your financial destiny. Trade stocks, currencies, commodities, and more, with transparent fees and advanced trading tools. Start your journey today and unlock boundless possibilities." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Accounts />
        <KeyFeatures />
        <Various />
        <Tools />
        <Transparent />
        <Ambitious />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
