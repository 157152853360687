import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FaqPage/underbanner.jpg';
import Button from "../../GlobalComponents/Button/Button";

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.underBanner}>
                <div className={classes.content}>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('faq.underbanner.text_01')}
                        </p>
                        <p>
                            {t('faq.underbanner.text_02')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
