import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import lines from '../../../assets/img/lines/lines_full_page.svg';
import image_01 from '../../../assets/img/AssetsPage/Tabs/image_01.avif';
import image_02 from '../../../assets/img/AssetsPage/Tabs/image_02.avif';
import image_03 from '../../../assets/img/AssetsPage/Tabs/image_03.avif';
import image_04 from '../../../assets/img/AssetsPage/Tabs/image_04.avif';
import image_05 from '../../../assets/img/AssetsPage/Tabs/image_05.avif';
import Tab from "../Tab/Tab";

const tabs = [
    {
        title: 'assets.tabs.stocks.title',
        text: 'assets.tabs.stocks.text',
        image: image_01,
        trading: {
            title: 'assets.tabs.stocks.trading.title',
            text: [
                'assets.tabs.stocks.trading.text_01',
                'assets.tabs.stocks.trading.text_02',
                'assets.tabs.stocks.trading.text_03',
            ],
        },
        tabs: [
            {
                title: 'assets.tabs.stocks.tab_01.title',
                text: 'assets.tabs.stocks.tab_01.text',
            },
            {
                title: 'assets.tabs.stocks.tab_02.title',
                text: 'assets.tabs.stocks.tab_02.text',
            },
            {
                title: 'assets.tabs.stocks.tab_03.title',
                text: 'assets.tabs.stocks.tab_03.text',
            },
            {
                title: 'assets.tabs.stocks.tab_04.title',
                text: 'assets.tabs.stocks.tab_04.text',
            },
        ],
    },
    {
        title: 'assets.tabs.crypto.title',
        text: 'assets.tabs.crypto.text',
        image: image_02,
        trading: {
            title: 'assets.tabs.crypto.trading.title',
            text: [
                'assets.tabs.crypto.trading.text_01',
                'assets.tabs.crypto.trading.text_02',
                'assets.tabs.crypto.trading.text_03',
            ],
        },
        tabs: [
            {
                title: 'assets.tabs.crypto.tab_01.title',
                text: 'assets.tabs.crypto.tab_01.text',
            },
            {
                title: 'assets.tabs.crypto.tab_02.title',
                text: 'assets.tabs.crypto.tab_02.text',
            },
            {
                title: 'assets.tabs.crypto.tab_03.title',
                text: 'assets.tabs.crypto.tab_03.text',
            },
            {
                title: 'assets.tabs.crypto.tab_04.title',
                text: 'assets.tabs.crypto.tab_04.text',
            },
            {
                title: 'assets.tabs.crypto.tab_05.title',
                text: 'assets.tabs.crypto.tab_05.text',
            },
            {
                title: 'assets.tabs.crypto.tab_06.title',
                text: 'assets.tabs.crypto.tab_06.text',
            },
        ],
    },
    {
        title: 'assets.tabs.commodities.title',
        text: 'assets.tabs.commodities.text',
        image: image_03,
        trading: {
            title: 'assets.tabs.commodities.trading.title',
            text: [
                'assets.tabs.commodities.trading.text_01',
                'assets.tabs.commodities.trading.text_02',
                'assets.tabs.commodities.trading.text_03',
            ],
        },
        tabs: [
            {
                title: 'assets.tabs.commodities.tab_01.title',
                text: 'assets.tabs.commodities.tab_01.text',
            },
            {
                title: 'assets.tabs.commodities.tab_02.title',
                text: 'assets.tabs.commodities.tab_02.text',
            },
            {
                title: 'assets.tabs.commodities.tab_03.title',
                text: 'assets.tabs.commodities.tab_03.text',
            },
            {
                title: 'assets.tabs.commodities.tab_04.title',
                text: 'assets.tabs.commodities.tab_04.text',
            },
            {
                title: 'assets.tabs.commodities.tab_05.title',
                text: 'assets.tabs.commodities.tab_05.text',
            },
            {
                title: 'assets.tabs.commodities.tab_06.title',
                text: 'assets.tabs.commodities.tab_06.text',
            },
        ],
    },
    {
        title: 'assets.tabs.indices.title',
        text: 'assets.tabs.indices.text',
        image: image_04,
        trading: {
            title: 'assets.tabs.indices.trading.title',
            text: [
                'assets.tabs.indices.trading.text_02',
                'assets.tabs.indices.trading.text_03',
            ],
        },
        tabs: [
            {
                title: 'assets.tabs.indices.tab_01.title',
                text: 'assets.tabs.indices.tab_01.text',
            },
            {
                title: 'assets.tabs.indices.tab_02.title',
                text: 'assets.tabs.indices.tab_02.text',
            },
            {
                title: 'assets.tabs.indices.tab_03.title',
                text: 'assets.tabs.indices.tab_03.text',
            },
            {
                title: 'assets.tabs.indices.tab_04.title',
                text: 'assets.tabs.indices.tab_04.text',
            },
            {
                title: 'assets.tabs.indices.tab_05.title',
                text: 'assets.tabs.indices.tab_05.text',
            },
            {
                title: 'assets.tabs.indices.tab_06.title',
                text: 'assets.tabs.indices.tab_06.text',
            },
        ],
    },
    {
        title: 'assets.tabs.forex.title',
        text: 'assets.tabs.forex.text',
        image: image_05,
        trading: {
            title: 'assets.tabs.forex.trading.title',
            text: [
                'assets.tabs.forex.trading.text_01',
                'assets.tabs.forex.trading.text_02',
                'assets.tabs.forex.trading.text_03',
            ],
        },
        tabs: [
            {
                title: 'assets.tabs.forex.tab_01.title',
                text: 'assets.tabs.forex.tab_01.text',
            },
            {
                title: 'assets.tabs.forex.tab_02.title',
                text: 'assets.tabs.forex.tab_02.text',
            },
            {
                title: 'assets.tabs.forex.tab_03.title',
                text: 'assets.tabs.forex.tab_03.text',
            },
            {
                title: 'assets.tabs.forex.tab_04.title',
                text: 'assets.tabs.forex.tab_04.text',
            },
            {
                title: 'assets.tabs.forex.tab_05.title',
                text: 'assets.tabs.forex.tab_05.text',
            },
            {
                title: 'assets.tabs.forex.tab_06.title',
                text: 'assets.tabs.forex.tab_06.text',
            },
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [ active, setActive ] = useState(0);
    return (
        <section className="container">
            <div className={classes.tabs}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index}
                            className={`${classes.btn} ${active === index ? classes.active : ''} no-select`}
                            onClick={() => setActive(index)}
                        >
                            {t(tab.title)}
                        </div>
                    )}
                </div>
                <img className={classes.lines} src={lines}  alt='' />
                <Tab data={tabs[active]} />
            </div>
        </section>
    );
};

export default Tabs;
