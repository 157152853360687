import React from "react";
import classes from './Transparent.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/transparent.avif';
import lines from '../../../assets/img/lines/lines_small.svg';

const cards = [
    {
        title: 'home.transparent.card_01.title',
        text: 'home.transparent.card_01.text',
    },
    {
        title: 'home.transparent.card_02.title',
        text: 'home.transparent.card_02.text',
    },
    {
        title: 'home.transparent.card_03.title',
        text: 'home.transparent.card_03.text',
    },
    {
        title: 'home.transparent.card_04.title',
        text: 'home.transparent.card_04.text',
    },
    {
        title: 'home.transparent.card_05.title',
        text: 'home.transparent.card_05.text',
    },
];

const Transparent = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.wrap} mt`}>
            <div className={classes.panel}>
                <img className={classes.image} src={image} alt='' />
                <div className="container">
                    <div className={classes.contentWrap}>
                        <div className={classes.content}>
                            <h2 className={`${classes.title} font-40`}>
                                {t('home.transparent.title')}
                            </h2>
                            <img className={classes.lines} src={lines} alt='' />
                            <p className={`${classes.text} font-16`}>
                                {t('home.transparent.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className={`${classes.cardTitle} font-22`}>
                                {t(card.title)}
                            </h4>
                            <p className={`${classes.cardText} font-16`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Transparent;
