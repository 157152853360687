import React, { useState } from "react";
import classes from './Newsletters.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const tabs = [
    {
        title: 'faq.newsletters.tab_01.title',
        text: 'faq.newsletters.tab_01.text',
    },
    {
        title: 'faq.newsletters.tab_02.title',
        text: 'faq.newsletters.tab_02.text',
    },
    {
        title: 'faq.newsletters.tab_03.title',
        text: 'faq.newsletters.tab_03.text',
    },
    {
        title: 'faq.newsletters.tab_04.title',
        text: 'faq.newsletters.tab_04.text',
    },
    {
        title: 'faq.newsletters.tab_05.title',
        text: 'faq.newsletters.tab_05.text',
    },
    {
        title: 'faq.newsletters.tab_06.title',
        text: 'faq.newsletters.tab_06.text',
    },
];

const Newsletters = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container mt">
            <h2 className={`${classes.title} font-40`}>
                {t('faq.newsletters.title')}
            </h2>
            <div className={classes.content}>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            className={`${classes.tab} ${active === index ? classes.active : ''}`}
                            onClick={() => setActive(index)}
                        >
                            <h4 className={`${classes.tabTitle} font-20`}>
                                {t(tab.title)}
                            </h4>
                            <p className={`${classes.tabText} font-16`}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.textWrap}>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('faq.newsletters.text_01')}
                        </p>
                        <p>
                            {t('faq.newsletters.text_02')}
                        </p>
                        <p>
                            {t('faq.newsletters.text_03')}
                        </p>
                        <p>
                            {t('faq.newsletters.text_04')}
                        </p>
                    </div>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Newsletters;
