import React, { useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'faq.faq.tab_01.title',
        text: 'faq.faq.tab_01.text',
    },
    {
        title: 'faq.faq.tab_02.title',
        text: 'faq.faq.tab_02.text',
    },
    {
        title: 'faq.faq.tab_03.title',
        text: 'faq.faq.tab_03.text',
    },
    {
        title: 'faq.faq.tab_04.title',
        text: 'faq.faq.tab_04.text',
    },
    {
        title: 'faq.faq.tab_05.title',
        text: 'faq.faq.tab_05.text',
    },
    {
        title: 'faq.faq.tab_06.title',
        text: 'faq.faq.tab_06.text',
    },
    {
        title: 'faq.faq.tab_07.title',
        text: 'faq.faq.tab_07.text',
    },
    {
        title: 'faq.faq.tab_08.title',
        text: 'faq.faq.tab_08.text',
    },
    {
        title: 'faq.faq.tab_09.title',
        text: 'faq.faq.tab_09.text',
    },
    {
        title: 'faq.faq.tab_10.title',
        text: 'faq.faq.tab_10.text',
    },
];

const Faq = () => {
    const { t } = useTranslation();
    const [ active, setActive ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.faq}>
                <h2 className={`${classes.title} font-40`}>
                    {t('faq.faq.title')}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            className={`${classes.tab} ${active === index ? classes.active : ''} font-16`}
                        >
                            <div
                                className={`${classes.tabHeader} no-select`}
                                onClick={() => setActive(index)}
                            >
                                <h4 className={classes.tabTitle}>
                                    <b>
                                        {t(tab.title)}
                                    </b>
                                </h4>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <p className={classes.tabText}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
