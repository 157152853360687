import React, { useState } from "react";
import classes from './Various.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Various/image_01.avif';
import image_02 from '../../../assets/img/HomePage/Various/image_02.avif';
import image_03 from '../../../assets/img/HomePage/Various/image_03.avif';
import image_04 from '../../../assets/img/HomePage/Various/image_04.avif';
import image_05 from '../../../assets/img/HomePage/Various/image_05.avif';
import image_06 from '../../../assets/img/HomePage/Various/image_06.avif';
import lines_svg from '../../../assets/img/lines/lines_large.svg';
import lines_small_svg from '../../../assets/img/lines/lines_small.svg';
import Button from "../../GlobalComponents/Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        text: 'home.various.instruments.card_01.text',
        image: image_01,
    },
    {
        text: 'home.various.instruments.card_02.text',
        image: image_02,
    },
    {
        text: 'home.various.instruments.card_03.text',
        image: image_03,
    },
    {
        text: 'home.various.instruments.card_04.text',
        image: image_04,
    },
    {
        text: 'home.various.instruments.card_05.text',
        image: image_05,
    },
    {
        text: 'home.various.instruments.card_06.text',
        image: image_06,
    },
];

const Various = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    return (
        <section className="mt container">
            <div className={classes.various}>
                <div className={classes.gtitleWrap}>
                    <h2 className={`${classes.gtitle} font-40`}>
                        <Trans>
                            {t('home.various.title')}
                        </Trans>
                        <img className={`${classes.lines} ${classes.linesLeft}`} src={lines_svg} alt='' />
                        <img className={`${classes.lines} ${classes.linesRight}`} src={lines_svg} alt='' />
                    </h2>
                </div>
                <div className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-40`}>
                            {t('home.various.instruments.title')}
                        </h2>
                        <img className={classes.linesSmall} src={lines_small_svg} alt='' />
                        <p className={`${classes.text} font-16`}>
                            <Trans>
                                {t('home.various.instruments.text')}
                            </Trans>
                        </p>
                        <div className="mt-btn">
                            <Button>
                                {t('btn.signup')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.swiperWrap}>
                            <div className={classes.nav}>
                                <div
                                    className={classes.navBtn}
                                    onClick={() => swiper.slidePrev()}
                                ></div>
                                <div
                                    className={`${classes.navBtn} ${classes.right}`}
                                    onClick={() => swiper.slideNext()}
                                ></div>
                            </div>
                            <Swiper
                                modules={[Navigation]}
                                className="variousSwiper no-select"
                                spaceBetween={20}
                                slidesPerView={1}
                                onInit={(e) => { setSwiper(e) }}
                                grabCursor
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="variousSwiperSlide">
                                        <div className={`${classes.card} font-16`}>
                                            <img className={classes.cardImage} src={card.image} alt='' />
                                            <p className={classes.cardText}>
                                                {t(card.text)}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Various;
