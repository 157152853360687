import React from "react";
import classes from './Explore.module.scss';
import { Trans, useTranslation } from "react-i18next";

const cards = [
    {
        title: 'home.explore.card_01.title',
        text: 'home.explore.card_01.text',
    },
    {
        title: 'home.explore.card_02.title',
        text: 'home.explore.card_02.text',
    },
    {
        title: 'home.explore.card_03.title',
        text: 'home.explore.card_03.text',
    },
];

const Explore = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.explore}>
                <h2 className={`${classes.title} font-40`}>
                    <Trans>
                        {t('home.explore.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <div className={`${classes.num} font-80`}>
                                {index + 1}
                            </div>
                            <h4 className={`${classes.cardTitle} font-16`}>
                                <b>
                                    {t(card.title)}
                                </b>
                            </h4>
                            <p className={`${classes.cardText} font-16`}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Explore;
