import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import lines from '../../../assets/img/lines/lines_full_page.svg';
import Tab from "../Tab/Tab";

const tabs = [
    {
        title_btn: 'fees.tabs.commission.title_btn',
        title: 'fees.tabs.commission.title',
        text: [
            'fees.tabs.commission.text_01',
            'fees.tabs.commission.text_02',
            'fees.tabs.commission.text_03',
        ],
        tabs: [
            {
                title: 'fees.tabs.commission.tab_01.title',
                text: 'fees.tabs.commission.tab_01.text',
            },
            {
                title: 'fees.tabs.commission.tab_02.title',
                text: 'fees.tabs.commission.tab_02.text',
            },
            {
                title: 'fees.tabs.commission.tab_03.title',
                text: 'fees.tabs.commission.tab_03.text',
            },
            {
                title: 'fees.tabs.commission.tab_04.title',
                text: 'fees.tabs.commission.tab_04.text',
            },
            {
                title: 'fees.tabs.commission.tab_05.title',
                text: 'fees.tabs.commission.tab_05.text',
            },
        ],
    },
    {
        title_btn: 'fees.tabs.spreads.title_btn',
        title: 'fees.tabs.spreads.title',
        text: [
            'fees.tabs.spreads.text_01',
            'fees.tabs.spreads.text_02',
            'fees.tabs.spreads.text_03',
        ],
        tabs: [
            {
                title: 'fees.tabs.spreads.tab_01.title',
                text: 'fees.tabs.spreads.tab_01.text',
            },
            {
                title: 'fees.tabs.spreads.tab_02.title',
                text: 'fees.tabs.spreads.tab_02.text',
            },
            {
                title: 'fees.tabs.spreads.tab_03.title',
                text: 'fees.tabs.spreads.tab_03.text',
            },
            {
                title: 'fees.tabs.spreads.tab_04.title',
                text: 'fees.tabs.spreads.tab_04.text',
            },
            {
                title: 'fees.tabs.spreads.tab_05.title',
                text: 'fees.tabs.spreads.tab_05.text',
            },
        ],
    },
    {
        title_btn: 'fees.tabs.charges.title_btn',
        title: 'fees.tabs.charges.title',
        text: [
            'fees.tabs.charges.text_01',
            'fees.tabs.charges.text_02',
            'fees.tabs.charges.text_03',
            'fees.tabs.charges.text_04',
        ],
        tabs: [
            {
                title: 'fees.tabs.charges.tab_01.title',
                text: 'fees.tabs.charges.tab_01.text',
            },
            {
                title: 'fees.tabs.charges.tab_02.title',
                text: 'fees.tabs.charges.tab_02.text',
            },
            {
                title: 'fees.tabs.charges.tab_03.title',
                text: 'fees.tabs.charges.tab_03.text',
            },
            {
                title: 'fees.tabs.charges.tab_04.title',
                text: 'fees.tabs.charges.tab_04.text',
            },
        ],
    },
    {
        title_btn: 'fees.tabs.inactivity.title_btn',
        title: 'fees.tabs.inactivity.title',
        text: [
            'fees.tabs.inactivity.text_01',
            'fees.tabs.inactivity.text_02',
            'fees.tabs.inactivity.text_03',
            'fees.tabs.inactivity.text_04',
        ],
        tabs: [
            {
                title: 'fees.tabs.inactivity.tab_01.title',
                text: 'fees.tabs.inactivity.tab_01.text',
            },
            {
                title: 'fees.tabs.inactivity.tab_02.title',
                text: 'fees.tabs.inactivity.tab_02.text',
            },
            {
                title: 'fees.tabs.inactivity.tab_03.title',
                text: 'fees.tabs.inactivity.tab_03.text',
            },
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [ active, setActive ] = useState(0);
    return (
        <section className="container mt">
            <div className={classes.tabs}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index}
                            className={`${classes.btn} ${active === index ? classes.active : ''} no-select`}
                            onClick={() => setActive(index)}
                        >
                            {t(tab.title_btn)}
                        </div>
                    )}
                </div>
                <img className={classes.lines} src={lines}  alt='' />
                <Tab data={tabs[active]} />
            </div>
        </section>
    );
};

export default Tabs;
