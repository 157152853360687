import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FeesPage/banner.jpg';
import lines from '../../../assets/img/lines/lines_small.svg';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className={`container ${classes.container}`}>
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-40`}>
                            {t('fees.banner.title')}
                        </h1>
                        <img className={classes.lines} src={lines} alt='' />
                        <p className={`${classes.text} font-16`}>
                            {t('fees.banner.text')}
                        </p>
                        <div className="mt-btn">
                            <Button>
                                {t('btn.signup')}
                            </Button>
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
