import React, { useState, useEffect } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import lines from '../../../assets/img/lines/lines_small.svg';
import image_01 from '../../../assets/img/ToolsPage/UnderBanner/image_01.avif';
import image_02 from '../../../assets/img/ToolsPage/UnderBanner/image_02.avif';
import image_03 from '../../../assets/img/ToolsPage/UnderBanner/image_03.avif';
import image_04 from '../../../assets/img/ToolsPage/UnderBanner/image_04.avif';
import image_05 from '../../../assets/img/ToolsPage/UnderBanner/image_05.avif';
import image_06 from '../../../assets/img/ToolsPage/UnderBanner/image_06.avif';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        image: image_01,
        title: 'tools.underbanner.card_01.title',
        text: 'tools.underbanner.card_01.text',
    },
    {
        image: image_02,
        title: 'tools.underbanner.card_02.title',
        text: 'tools.underbanner.card_02.text',
    },
    {
        image: image_03,
        title: 'tools.underbanner.card_03.title',
        text: 'tools.underbanner.card_03.text',
    },
    {
        image: image_04,
        title: 'tools.underbanner.card_04.title',
        text: 'tools.underbanner.card_04.text',
    },
    {
        image: image_05,
        title: 'tools.underbanner.card_05.title',
        text: 'tools.underbanner.card_05.text',
    },
    {
        image: image_06,
        title: 'tools.underbanner.card_06.title',
        text: 'tools.underbanner.card_06.text',
    },
];

const SwiperNav = ({ swiper }) => {
    return (
        <div className={classes.nav}>
            <div
                className={classes.navBtn}
                onClick={() => swiper.slidePrev()}
            ></div>
            <div
                className={`${classes.navBtn} ${classes.right}`}
                onClick={() => swiper.slideNext()}
            ></div>
        </div>
    );
};

const UnderBanner = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [active, setActive] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={`${classes.wrap}`}>
            <div className={classes.panel}>
                {windowWidth > 992 &&
                    <div className={classes.images}>
                        {cards.map((card, index) =>
                            <img
                                key={index}
                                className={`${classes.image} ${active === index ? classes.active : ''}`}
                                src={card.image}
                                alt=''
                            />
                        )}
                    </div>
                }
                <div className="container">
                    <div className={classes.contentWrap}>
                        <div className={classes.swiperWrap}>
                            {windowWidth <= 992 &&
                                <SwiperNav swiper={swiper} />
                            }
                            <Swiper
                                modules={[Navigation]}
                                className="toolsUndbSwiper no-select"
                                spaceBetween={20}
                                slidesPerView={1}
                                onInit={(e) => { setSwiper(e) }}
                                onSlideChange={(e) => setActive(e.activeIndex)}
                                grabCursor
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="toolsUndbSwiperSlide">
                                        <div className={classes.card}>
                                            {windowWidth <= 992 &&
                                                <img className={classes.cardImage} src={card.image} alt='' />
                                            }
                                            <div className={classes.cardContent}>
                                                <h2 className={`${classes.title} font-30`}>
                                                    {t(card.title)}
                                                </h2>
                                                <img className={classes.lines} src={lines} alt='' />
                                                <p className={`${classes.text} font-16`}>
                                                    {t(card.text)}
                                                </p>
                                            </div>
                                        </div>

                                    </SwiperSlide>
                                )}
                            </Swiper>
                            {/* <div className={classes.nav}>
                                <div
                                    className={classes.navBtn}
                                    onClick={() => swiper.slidePrev()}
                                ></div>
                                <div
                                    className={`${classes.navBtn} ${classes.right}`}
                                    onClick={() => swiper.slideNext()}
                                ></div>
                            </div> */}
                            {windowWidth > 992 &&
                                <SwiperNav swiper={swiper} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
