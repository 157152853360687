import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Explore from "../../components/HomePage/Explore/Explore";
import Banner from "../../components/FeesPage/Banner/Banner";
import UnderBanner from "../../components/FeesPage/UnderBanner/UnderBanner";
import Tabs from "../../components/FeesPage/Tabs/Tabs";

const FeesPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo · Fees</title>
        <meta name="title" content="Transparent and Competitive Fee Structure at Brabo" />
        <meta property="og:title" content="Transparent and Competitive Fee Structure at Brabo" />
        <meta property="twitter:title" content="Transparent and Competitive Fee Structure at Brabo" />

        <meta name="description" content="At Brabo, we believe in transparency and fairness. Our fee structure is designed to be transparent and competitive, ensuring you have a clear understanding of the costs associated with your trades. Explore our explicit fee schedule, including commission fees, spreads, and overnight financing charges, empowering you to make well-informed trading decisions." />
        <meta property="og:description" content="At Brabo, we believe in transparency and fairness. Our fee structure is designed to be transparent and competitive, ensuring you have a clear understanding of the costs associated with your trades. Explore our explicit fee schedule, including commission fees, spreads, and overnight financing charges, empowering you to make well-informed trading decisions." />
        <meta property="twitter:description" content="At Brabo, we believe in transparency and fairness. Our fee structure is designed to be transparent and competitive, ensuring you have a clear understanding of the costs associated with your trades. Explore our explicit fee schedule, including commission fees, spreads, and overnight financing charges, empowering you to make well-informed trading decisions." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Tabs />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FeesPage;
