import React, { useRef, useState, useEffect } from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import phone_01 from '../../../assets/img/HomePage/Banner/phone_01.webp';
import phone_02 from '../../../assets/img/HomePage/Banner/phone_02.webp';
import graph from '../../../assets/img/HomePage/Banner/graph.webp';
import coin_01 from '../../../assets/img/HomePage/Banner/coin_01.webp';
import coin_02 from '../../../assets/img/HomePage/Banner/coin_02.webp';
import coin_03 from '../../../assets/img/HomePage/Banner/coin_03.webp';
import lines_small from '../../../assets/img/lines/lines_small.svg';
import image_mob from '../../../assets/img/HomePage/Banner/image_mob.jpg';

const Banner = () => {
    const { t } = useTranslation();
    const phoneRef_01 = useRef(null);
    const phoneRef_02 = useRef(null);
    const graphRef = useRef(null);
    const coinRef_01 = useRef(null);
    const coinRef_02 = useRef(null);
    const coinRef_03 = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <img className={classes.lines} src={lines_small} alt='' />
                    <h1 className={`${classes.title} font-60`}>
                        <Trans>
                            {t('home.banner.title')}
                        </Trans>
                    </h1>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.signup')}
                        </Button>
                    </div>
                </div>
                {windowWidth > 1220 ?
                    <div className={classes.image}>
                        <img ref={phoneRef_01} className={classes.phone_01} src={phone_01} alt='' />
                        <img ref={phoneRef_02} className={classes.phone_02} src={phone_02} alt='' />
                        <img ref={graphRef} className={classes.graph} src={graph} alt='' />
                        <img ref={coinRef_01} className={classes.coin_01} src={coin_01} alt='' />
                        <img ref={coinRef_02} className={classes.coin_02} src={coin_02} alt='' />
                        <img ref={coinRef_03} className={classes.coin_03} src={coin_03} alt='' />
                    </div>
                    :
                    <img className={classes.imageMob} src={image_mob} alt='' />
                }
            </div>
        </section>
    );
};

export default Banner;
