import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Explore from "../../components/HomePage/Explore/Explore";
import Legacy from "../../components/AboutPage/Legacy/Legacy";
import OurMission from "../../components/AboutPage/OurMission/OurMission";
import Banner from "../../components/AboutPage/Banner/Banner";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Brabo · About us</title>
        <meta name="title" content="Brabo: Empowering Traders Worldwide" />
        <meta property="og:title" content="Brabo: Empowering Traders Worldwide" />
        <meta property="twitter:title" content="Brabo: Empowering Traders Worldwide" />

        <meta name="description" content="Brabo is a leading trading platform dedicated to empowering traders worldwide. Our mission is to equip you with the tools, resources, and unwavering support necessary to achieve your financial goals. With a wide range of tradable assets, advanced trading tools, transparent fees, and comprehensive support, we're here to help you succeed in the dynamic world of trading." />
        <meta property="og:description" content="Brabo is a leading trading platform dedicated to empowering traders worldwide. Our mission is to equip you with the tools, resources, and unwavering support necessary to achieve your financial goals. With a wide range of tradable assets, advanced trading tools, transparent fees, and comprehensive support, we're here to help you succeed in the dynamic world of trading." />
        <meta property="twitter:description" content="Brabo is a leading trading platform dedicated to empowering traders worldwide. Our mission is to equip you with the tools, resources, and unwavering support necessary to achieve your financial goals. With a wide range of tradable assets, advanced trading tools, transparent fees, and comprehensive support, we're here to help you succeed in the dynamic world of trading." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurMission />
        <Legacy />
        <Explore />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
