import React, { useEffect, useState } from "react";
import classes from './Tab.module.scss';
import { useTranslation } from "react-i18next";
import lines_small_svg from '../../../assets/img/lines/lines_small.svg';
import Button from "../../GlobalComponents/Button/Button";

const Tab = ({ data }) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const { title, text, image, trading, tabs } = data;

    useEffect(() => {
        setActive(0);
    }, [title]);

    return (
        <div className={classes.various}>
            <div className={classes.head}>
                <img className={classes.headImage} src={image} alt='' />
                <div className={classes.headContent}>
                    <h3 className="font-40">
                        {t(title)}
                    </h3>
                    <img className={classes.headContentLines} src={lines_small_svg} alt='' />
                    <p className="font-16">
                        {t(text)}
                    </p>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.start_trading')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={`${classes.block} mt`}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-40`}>
                        {t(trading.title)}
                    </h2>
                    <img className={classes.linesSmall} src={lines_small_svg} alt='' />
                    <div className={`${classes.text} font-16`}>
                        {trading.text.map((p, pIndex) =>
                            <p key={pIndex}>
                                {t(p)}
                            </p>
                        )}
                    </div>
                </div>
                <div className={classes.col}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            className={`${classes.tab} ${active === index ? classes.active : ''} font-16`}
                        >
                            <div
                                className={`${classes.tabHeader} no-select`}
                                onClick={() => setActive(index)}
                            >
                                <h4 className={classes.tabTitle}>
                                    <b>
                                        {t(tab.title)}
                                    </b>
                                </h4>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <p className={classes.tabText}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Tab;
