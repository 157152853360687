import React, { useState, useEffect } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/FaqPage/image.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        title: 'faq.banner.card_01.title',
        text: 'faq.banner.card_01.text',
    },
    {
        title: 'faq.banner.card_02.title',
        text: 'faq.banner.card_02.text',
    },
    {
        title: 'faq.banner.card_03.title',
        text: 'faq.banner.card_03.text',
    },
    {
        title: 'faq.banner.card_04.title',
        text: 'faq.banner.card_04.text',
    },
    {
        title: 'faq.banner.card_05.title',
        text: 'faq.banner.card_05.text',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="container">
            <div className={classes.banner}>
                {windowWidth > 1220 &&
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                }
                <div className={classes.content}>
                    <div className={classes.head}>
                        <h1 className={`${classes.title} font-40`}>
                            {t('faq.banner.title')}
                        </h1>
                        <p className={`${classes.text} font-16`}>
                            {t('faq.banner.text')}
                        </p>
                    </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="faqBannerSwiper no-select"
                            grabCursor
                            spaceBetween={20}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="faqBannerSwiperSlide">
                                    <div className={classes.card}>
                                        <h4 className={`${classes.cardTitle} font-20`}>
                                            {t(card.title)}
                                        </h4>
                                        <p className={classes.cardText}>
                                            {t(card.text)}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div className={classes.nav}>
                            <div
                                className={classes.navBtn}
                                onClick={() => swiper.slidePrev()}
                            ></div>
                            <div
                                className={`${classes.navBtn} ${classes.right}`}
                                onClick={() => swiper.slideNext()}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
