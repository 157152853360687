import React, { useState } from "react";
import classes from './Tools.module.scss';
import { Trans, useTranslation } from "react-i18next";
import lines_small_svg from '../../../assets/img/lines/lines_small.svg';
import Button from "../../GlobalComponents/Button/Button";

const tabs = [
    {
        title: 'home.various.tools.tab_01.title',
        text: 'home.various.tools.tab_01.text',
    },
    {
        title: 'home.various.tools.tab_02.title',
        text: 'home.various.tools.tab_02.text',
    },
    {
        title: 'home.various.tools.tab_03.title',
        text: 'home.various.tools.tab_03.text',
    },
    {
        title: 'home.various.tools.tab_04.title',
        text: 'home.various.tools.tab_04.text',
    },
    {
        title: 'home.various.tools.tab_05.title',
        text: 'home.various.tools.tab_05.text',
    },
];

const Tools = () => {
    const { t } = useTranslation();
    const [ active, setActive ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.various}>
                <div className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-40`}>
                            {t('home.various.tools.title')}
                        </h2>
                        <img className={classes.linesSmall} src={lines_small_svg} alt='' />
                        <p className={`${classes.text} font-16`}>
                            <Trans>
                                {t('home.various.tools.text')}
                            </Trans>
                        </p>
                        <div className="mt-btn">
                            <Button>
                                {t('btn.signup')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.col}>
                        {tabs.map((tab, index) =>
                            <div 
                                key={index} 
                                className={`${classes.tab} ${active === index ? classes.active : ''} font-16`}
                            >
                                <div 
                                    className={`${classes.tabHeader} no-select`}
                                    onClick={() => setActive(index)}
                                >
                                    <h4 className={classes.tabTitle}>
                                        <b>
                                            {t(tab.title)}
                                        </b>
                                    </h4>
                                    <div className={classes.tabStatus}></div>
                                </div>
                                <p className={classes.tabText}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tools;
