import React, { useEffect, useState } from "react";
import classes from './Advanced.module.scss';
import { useTranslation } from "react-i18next";

const options = [
    {
        title: 'tools.advanced.option_01.title',
        text_01: 'tools.advanced.option_01.text_01',
        text_02: 'tools.advanced.option_01.text_02',
        text_03: 'tools.advanced.option_01.text_03',
        tabs: [
            {
                title: 'tools.advanced.option_01.tab_01.title',
                text: 'tools.advanced.option_01.tab_01.text',
            },
            {
                title: 'tools.advanced.option_01.tab_02.title',
                text: 'tools.advanced.option_01.tab_02.text',
            },
            {
                title: 'tools.advanced.option_01.tab_03.title',
                text: 'tools.advanced.option_01.tab_03.text',
            },
            {
                title: 'tools.advanced.option_01.tab_04.title',
                text: 'tools.advanced.option_01.tab_04.text',
            },
            {
                title: 'tools.advanced.option_01.tab_05.title',
                text: 'tools.advanced.option_01.tab_05.text',
            },
            {
                title: 'tools.advanced.option_01.tab_06.title',
                text: 'tools.advanced.option_01.tab_06.text',
            },
        ],
    },
    {
        title: 'tools.advanced.option_02.title',
        text_01: 'tools.advanced.option_02.text_01',
        text_02: 'tools.advanced.option_02.text_02',
        text_03: 'tools.advanced.option_02.text_03',
        tabs: [
            {
                title: 'tools.advanced.option_02.tab_01.title',
                text: 'tools.advanced.option_02.tab_01.text',
            },
            {
                title: 'tools.advanced.option_02.tab_02.title',
                text: 'tools.advanced.option_02.tab_02.text',
            },
            {
                title: 'tools.advanced.option_02.tab_03.title',
                text: 'tools.advanced.option_02.tab_03.text',
            },
            {
                title: 'tools.advanced.option_02.tab_04.title',
                text: 'tools.advanced.option_02.tab_04.text',
            },
            {
                title: 'tools.advanced.option_02.tab_05.title',
                text: 'tools.advanced.option_02.tab_05.text',
            },
            {
                title: 'tools.advanced.option_02.tab_06.title',
                text: 'tools.advanced.option_02.tab_06.text',
            },
        ],
    },
    {
        title: 'tools.advanced.option_03.title',
        text_01: 'tools.advanced.option_03.text_01',
        text_02: 'tools.advanced.option_03.text_02',
        text_03: 'tools.advanced.option_03.text_03',
        tabs: [
            {
                title: 'tools.advanced.option_03.tab_01.title',
                text: 'tools.advanced.option_03.tab_01.text',
            },
            {
                title: 'tools.advanced.option_03.tab_02.title',
                text: 'tools.advanced.option_03.tab_02.text',
            },
            {
                title: 'tools.advanced.option_03.tab_03.title',
                text: 'tools.advanced.option_03.tab_03.text',
            },
            {
                title: 'tools.advanced.option_03.tab_04.title',
                text: 'tools.advanced.option_03.tab_04.text',
            },
            {
                title: 'tools.advanced.option_03.tab_05.title',
                text: 'tools.advanced.option_03.tab_05.text',
            },
            {
                title: 'tools.advanced.option_03.tab_06.title',
                text: 'tools.advanced.option_03.tab_06.text',
            },
        ],
    },
    {
        title: 'tools.advanced.option_04.title',
        text_01: 'tools.advanced.option_04.text_01',
        text_02: 'tools.advanced.option_04.text_02',
        text_03: 'tools.advanced.option_04.text_03',
        tabs: [
            {
                title: 'tools.advanced.option_04.tab_01.title',
                text: 'tools.advanced.option_04.tab_01.text',
            },
            {
                title: 'tools.advanced.option_04.tab_02.title',
                text: 'tools.advanced.option_04.tab_02.text',
            },
            {
                title: 'tools.advanced.option_04.tab_03.title',
                text: 'tools.advanced.option_04.tab_03.text',
            },
            {
                title: 'tools.advanced.option_04.tab_04.title',
                text: 'tools.advanced.option_04.tab_04.text',
            },
            {
                title: 'tools.advanced.option_04.tab_05.title',
                text: 'tools.advanced.option_04.tab_05.text',
            },
            {
                title: 'tools.advanced.option_04.tab_06.title',
                text: 'tools.advanced.option_04.tab_06.text',
            },
        ],
    },
    {
        title: 'tools.advanced.option_05.title',
        text_01: 'tools.advanced.option_05.text_01',
        text_02: 'tools.advanced.option_05.text_02',
        text_03: 'tools.advanced.option_05.text_03',
        tabs: [
            {
                title: 'tools.advanced.option_05.tab_01.title',
                text: 'tools.advanced.option_05.tab_01.text',
            },
            {
                title: 'tools.advanced.option_05.tab_02.title',
                text: 'tools.advanced.option_05.tab_02.text',
            },
            {
                title: 'tools.advanced.option_05.tab_03.title',
                text: 'tools.advanced.option_05.tab_03.text',
            },
            {
                title: 'tools.advanced.option_05.tab_04.title',
                text: 'tools.advanced.option_05.tab_04.text',
            },
            {
                title: 'tools.advanced.option_05.tab_05.title',
                text: 'tools.advanced.option_05.tab_05.text',
            },
            {
                title: 'tools.advanced.option_05.tab_06.title',
                text: 'tools.advanced.option_05.tab_06.text',
            },
        ],
    },
];

const Advanced = () => {
    const { t } = useTranslation();
    const [optionActive, setOptionActive] = useState(0);
    const [tabActive, setTabActive] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setTabActive(0);
    }, [optionActive]);

    return (
        <section className="mt container">
            <div className={classes.advanced}>
                <div className={classes.col}>
                    <div className={classes.options}>
                        {options.map((option, index) =>
                            <div
                                key={index}
                                className={`${classes.option} ${optionActive === index ? classes.active : ''}`}
                                onClick={() => setOptionActive(index)}
                            >
                                {t(option.title)}
                            </div>
                        )}
                    </div>
                    {windowWidth > 992 &&
                        <div className={`${classes.text} ${classes.textLeft} font-16`}>
                            <p>
                                {t(options[optionActive].text_02)}
                            </p>
                            <p>
                                {t(options[optionActive].text_03)}
                            </p>
                        </div>
                    }
                </div>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <h3 className="font-40">
                            {t(options[optionActive].title)}
                        </h3>
                        <div className={`${classes.text} font-16`}>
                            <p>
                                {t(options[optionActive].text_01)}
                            </p>
                            {windowWidth <= 992 &&
                                <p>
                                    {t(options[optionActive].text_02)}
                                </p>
                            }
                            {windowWidth <= 992 &&
                                <p>
                                    {t(options[optionActive].text_03)}
                                </p>
                            }
                        </div>
                    </div>
                    <div className={classes.tabs}>
                        {options[optionActive].tabs.map((tab, index) =>
                            <div
                                key={index}
                                className={`${classes.tab} ${tabActive === index ? classes.active : ''} font-16`}
                            >
                                <div
                                    className={`${classes.tabHeader} no-select`}
                                    onClick={() => setTabActive(index)}
                                >
                                    <h4 className={classes.tabTitle}>
                                        <b>
                                            {t(tab.title)}
                                        </b>
                                    </h4>
                                    <div className={classes.tabStatus}></div>
                                </div>
                                <p className={classes.tabText}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Advanced;
