import React from "react";
import classes from './Tab.module.scss';
import { useTranslation } from "react-i18next";
import lines_small_svg from '../../../assets/img/lines/lines_small.svg';
import Button from "../../GlobalComponents/Button/Button";

const Tab = ({ data }) => {
    const { t } = useTranslation();
    const { title, text, tabs } = data;
    return (
        <div className={classes.various}>
            <div className={classes.block}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-40`}>
                        {t(title)}
                    </h2>
                    <img className={classes.linesSmall} src={lines_small_svg} alt='' />
                    <div className={`${classes.text} font-16`}>
                        {text.map((p, pIndex) =>
                            <p key={pIndex}>
                                {t(p)}
                            </p>
                        )}
                    </div>
                    <div className="mt-btn">
                        <Button>
                            {t('btn.start_trading')}
                        </Button>
                    </div>
                </div>
                <div className={classes.col}>
                    {tabs.map((tab, index) =>
                        <div key={index} className={classes.tab}>
                            <h4 className="font-22">
                                {t(tab.title)}
                            </h4>
                            <p className="font-16">
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Tab;
