import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/HomePage/Banner/phone_01.webp';
import img2 from '../assets/img/HomePage/Banner/phone_02.webp';
import img3 from '../assets/img/HomePage/Banner/coin_01.webp';
import img4 from '../assets/img/HomePage/Banner/coin_02.webp';
import img5 from '../assets/img/HomePage/Banner/coin_03.webp';
import img6 from '../assets/img/HomePage/Banner/graph.webp';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
];
